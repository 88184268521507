#turn {
    display: none;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.browser-alert {
    background-color: #c23934;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 9;

    position: absolute;
    /* The code that you need to copy */
    left: 0px;
    right: 0px;
    top: 0px;
}
//@media only screen and (orientation:landscape) and (max-width: 1023.98px) {
//    #turn {
//        //display: flex;
//        justify-content: center;
//        align-items: center;
//        min-height: 100vh;
//    }
//    #app {
//        display:none;
//    }
//}
//
///* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
//@media only screen and (orientation:portrait) {
//    #turn {
//        display:none;
//    }
//
//    #app {
//        display:block;
//    }
//}

a {
    cursor: pointer;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}
.button--active {
    background-color: #DBDCDE !important;
}

.share-popover {
    max-width: 300px;
    background-color: #fff;
    padding: 0;

    &.tooltip {
        pointer-events: all;
    }

    .close-popover {
        position: absolute;
        top: -3px;
        right: -3px;
        z-index: 1001;
        cursor: pointer;
        pointer-events: all;
    }
}

.tooltip {
    &.popover {
        .popover-inner {
            padding: 15px 24px !important;
        }
    }

    .tooltip-inner {
        padding: 0 !important;
        background: none !important;
    }

    .tooltip-arrow {
        border-color: #23202E !important;
    }
}

.header__search--cont {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    position: relative;

    .header--search__results {
        position: absolute;
        top: 48px;
        width: 100%;
        box-shadow: 0 2px 4px #00000029;

        ul {
            display: block;
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            padding: 5px 0;
            background: #FFF;
            font-size: 16px;
            line-height: 20px;
            color: #000;
            list-style-type: none;

            li {
                padding: 10px 10px 10px 40px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    width: 18px;
                    height: 18px;
                    margin-top: -9px;
                    border-radius: 50%;
                }

                &.result--search::before {
                    background: #FFF url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2362727B" stroke-width="2.4" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>') 0 50% no-repeat;
                }

                &.result--video::before {
                    background: #20D3B3 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23FFF" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>') 55% 50%/8px 8px no-repeat;
                }

                &.result--iframe::before {
                    background: #9A9AF7 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%23FFF" d="M234.8 511.7L196 500.4c-4.2-1.2-6.7-5.7-5.5-9.9L331.3 5.8c1.2-4.2 5.7-6.7 9.9-5.5L380 11.6c4.2 1.2 6.7 5.7 5.5 9.9L244.7 506.2c-1.2 4.3-5.6 6.7-9.9 5.5zm-83.2-121.1l27.2-29c3.1-3.3 2.8-8.5-.5-11.5L72.2 256l106.1-94.1c3.4-3 3.6-8.2.5-11.5l-27.2-29c-3-3.2-8.1-3.4-11.3-.4L2.5 250.2c-3.4 3.2-3.4 8.5 0 11.7L140.3 391c3.2 3 8.2 2.8 11.3-.4zm284.1.4l137.7-129.1c3.4-3.2 3.4-8.5 0-11.7L435.7 121c-3.2-3-8.3-2.9-11.3.4l-27.2 29c-3.1 3.3-2.8 8.5.5 11.5L503.8 256l-106.1 94.1c-3.4 3-3.6 8.2-.5 11.5l27.2 29c3.1 3.2 8.1 3.4 11.3.4z"></path></svg>') 50% 50%/12px 12px no-repeat;
                }

                &.result--article::before {
                    background: #DC7DCD url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23FFF" d="M14,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V8H15a1,1,0,0,1-1-1ZM5.5,17h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,17Zm0-5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,12Zm5-3h-5A.5.5,0,0,1,5,8.5v-1A.5.5,0,0,1,5.5,7h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,10.5,9Z"></path><polygon fill="%23FFF" points="21.414 6 16 6 16 0.586 21.414 6"></polygon></svg>') 50% 50%/10px 10px no-repeat;
                }

                &:hover {
                    cursor: pointer;
                    background: #f4f4f4;
                }

                a {
                    p.result--title {
                        color: #555;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    p.result--description {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}


.tiptap-menubar {
    background: #EEEFF0 !important;
}

.tiptap-menubar button {
    background: #EEEFF0 !important;
}

.btn-content {
    color: #62727B !important;
}

.editor__content {
    color: #37474F !important;
}

.tutorial {
    width: 420px !important;
    max-width: 420px !important;
}

.v-step {
    max-width: 420px !important;
    padding: 0 !important;
    color: inherit !important;
}

.shepherd-header {
    background-color: white !important;
    padding-bottom: 0px !important;
}

.next-button {
    background-color: #ee2e24!important;
    color: white !important;
}

.end-tour-button {
    position: fixed;
    left: 0;
    color: #1097E2 !important;
    background: inherit !important;
}

.step-description {
    color: #62727B !important;
}

.shepherd-target {
    padding-top: 5px!important;
}

.shepherd-title {
    flex: auto !important;
    flex-direction: column !important;
}

.shepherd-text {
    line-height: 25px !important;
}

.shepherd-content {
    position: relative;
    max-width: 420px;
    padding: 20px;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: none !important;
}

.shepherd-arrow::before {
    background-color: white !important;
}

#card-categories {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .feed-step .shepherd-arrow:before {
        bottom: 115px !important;
    }

    .grid-cont--xl {
        max-width: 80%;
    }

    #card-categories {
        display: flex;
    }
}

.ctt-header {
    height: 51px;
    z-index: 99;
}

.ctt-switcher__item, .ctt-tabs__item {
    cursor: pointer;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 300;
    cursor: pointer;
}

.ctt-card__category--favorites {
    color: rgba(236, 179, 62, 1);
}
.ctt-card__category--favorites::after {
    background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="%23ECB33E" xmlns="http://www.w3.org/2000/svg"><path d="M11.6053 6.74999C11.3926 6.74999 11.2031 6.61534 11.1332 6.41441L9.47219 1.64414C9.3164 1.19669 8.6836 1.19669 8.52781 1.64414L6.86685 6.41441C6.79688 6.61534 6.60742 6.74999 6.39465 6.74999H1.44986C0.974571 6.74999 0.7674 7.35073 1.14165 7.64371L5.22586 10.8409C5.39123 10.9704 5.45808 11.1894 5.39319 11.3891L3.81445 16.2485C3.6648 16.7092 4.19203 17.0922 4.58388 16.8075L8.70611 13.8125C8.88135 13.6852 9.11865 13.6852 9.29389 13.8125L13.4161 16.8075C13.808 17.0922 14.3352 16.7092 14.1855 16.2485L12.6068 11.3891C12.5419 11.1894 12.6088 10.9704 12.7741 10.8409L16.8583 7.64371C17.2326 7.35073 17.0254 6.74999 16.5501 6.74999H11.6053Z"/></svg>');
}

.ctt-card {
    &.shadow-legacy {
        box-shadow: 0 0 11px rgba(245, 194, 66, 0.40);
    }

    &.shadow-favorites {
        box-shadow: 0 0 11px rgba(218, 161, 26, 0.40);
    }

    &.shadow-loves {
        box-shadow: 0 0 11px rgba(87, 113, 204, 0.40);
    }
    &.shadow-memories {
        box-shadow: 0 0 11px rgba(58, 153, 241, 0.40);
    }
    &.shadow-relationships {
        box-shadow: 0 0 11px rgba(229, 84, 136, 0.40);
    }
    &.shadow-reflections {
        box-shadow: 0 0 11px rgba(93, 191, 162, 0.40);
    }
    &.shadow-roadmap {
        box-shadow: 0 0 11px rgba(241, 99, 30, 0.40);
    }
    &.shadow-wishes {
        box-shadow: 0 0 11px rgba(148, 93, 191, 0.40);
    }

    &.border-legacy {
        border: 1px solid rgb(245, 194, 66);
    }

    &.border-favorites {
        border: 1px solid rgb(218, 161, 26);
    }

    &.border-loves {
        border: 1px solid rgb(87, 113, 204);
    }
    &.border-memories {
        border: 1px solid rgb(58, 153, 241);
    }
    &.border-relationships {
        border: 1px solid rgb(229, 84, 136);
    }
    &.border-reflections {
        border: 1px solid rgb(93, 191, 162);
    }
    &.border-roadmap {
        border: 1px solid rgb(241, 99, 30);
    }
    &.border-wishes {
        border: 1px solid rgb(148, 93, 191);
    }
}

.ctt-subheader {
    .shepherd-target {
        padding: 0 !important;
    }
}

.ctt-banner {
    position: fixed;
    z-index: 9999;
    left: 10%;
    width: 80%;
    top: 6px;
}

.ctt-modal {
    width: 90%;
}

.ctt-banner.danger {
    padding: 6px 30px;
    background-color: #c94242;
    font-weight: 500;
    text-align: center;
    color: #FFF;
}
.ctt-banner.danger::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 17px;
    height: 22px;
    margin-right: 8px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M17.1875 4.8125L4.8125 17.1875"/><path d="M17.1875 17.1875L4.8125 4.8125"/></svg>') 50% 50%/22px 22px no-repeat;
}

.ctt-deck-nav {
    display: none;
}

#logo {
    display: flex;
    vertical-align: middle;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.ctt-header__logo {
    display: inline-block;
    margin-right: 10px;
}

.ctt-header__slogan__big {
    display: inline-block;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #48415C;
}

@media screen and (max-width: 1280px) {
    .removed--lg {
        display: none;
    }
    .blocked--lg {
        display: block;
    }

    .flexed--lg {
        display: flex;
    }
}
@media screen and (max-width: 1024px) {
    .removed--md {
        display: none;
    }
    .blocked--md {
        display: block;
    }

    .flexed--md {
        display: flex;
    }

    .h2--sm {
        font-size: 23px;
    }

    #logo {
        position: static;
        transform: none;
    }
}
@media screen and (max-width: 768px) {
    .removed--sm {
        display: none;
    }
    .blocked--sm {
        display: block;
    }

    .flexed--sm {
        display: flex;
    }

    .onboard-quiz__head {
        padding: 5px 32px;
    }

    .onboard-questionnaire {
        margin-left: 10px;
        margin-right: 10px;
    }

    .onboard-questionnaire__item {
        padding: 5px;
    }
    .onboard-questionnaire__answers {
        margin: 0;
        padding: 5px;
    }

    .onboard-questionnaire__answer {
        min-height: auto;
        padding: 10px 15px;
    }

    .onboard-questionnaire__heading {
        padding-left: 10px;
    }

    .h2--sm {
        font-size: 23px;
    }

    .onboard__slide {
        background-size: 50%;
    }
}
@media screen and (max-width: 600px) {
    .removed--xs {
        display: none;
    }
    .blocked--xs {
        display: block;
    }

    .flexed--xs {
        display: flex;
    }

    .ctt-card__added-to-deck {
        font-size: 10px;
    }

    .onboard-quiz__head {
        padding: 5px;
    }

    .h2--sm {
        font-size: 23px;
    }

    .onboard__slide {
        background-size: 50%;
    }
}

@media screen and (max-height: 360px) {
    .onboard-intro__head {
        padding: 6px;
    }

    .onboard-intro {
        align-items: end;
        padding-bottom: 5px;
    }

    .onboard-quiz__head {
        padding: 5px;
    }

    .onboard__slide {
        background-size: 50%;
    }
}

@media screen and (max-width: 1023.98px) {
    .ctt-header__logo {
        position: static;
        transform: none;
    }
}

